import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import {
  LoginContext,
  RegisterFormWrapper,
} from "social-supermarket-components"
import Header from "../social-supermarket/components/header/Header"
import { getSourceTrackingInfo, isRunTime } from "../social-supermarket/util/generalUtil"
import { navigate } from "gatsby"
import Tile from "../social-supermarket/components/dashboard/Tile"
import GLink from "../gatsby/GLink"
import SEO from "../social-supermarket/components/Seo"
import TeaserBackground from "../social-supermarket/components/TeaserBackground"
import { getConfig } from "../config"
import * as tracker from "../social-supermarket/tracking/tracker"
import { colors } from "social-supermarket-model"

interface Props {}
const Container = styled.div`
  background-color: ${colors.lightGray};
`
const BottomContainer = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  width: 100vw;
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.2s ease-in-out;
`

const FormContainer = styled(Tile)`
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
  z-index: 9999;
`
const Links = styled.div`
  font-size: 0.8em;
  margin-top: 20px;
`
const Body = styled.div`
  flex: 1;
  width: 100vw;
  min-height: calc(100vh - 50px);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.2s ease-in-out;
`

const Register: FC<Props> = ({}) => {
  const { user } = useContext(LoginContext)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    if (isRunTime() && user) {
      navigate("/")
    }
  }, [user])

  const handleSuccess = () => {
    tracker.platformRegister()
    navigate("/")
  }

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 50)
  }, [])

  return (
    <Container>
      <SEO title={"Register"} description={""} />
      <Header />
      <TeaserBackground />

      <BottomContainer isVisible={isVisible}>
        {isVisible && (
          <FormContainer>
            <RegisterFormWrapper
              baseUrl={getConfig().baseUrl}
              onSuccess={handleSuccess}
              utm={getSourceTrackingInfo()}
            />
            <Links>
              <GLink to={"/"}>Go Back</GLink>
            </Links>
          </FormContainer>
        )}
      </BottomContainer>
    </Container>
  )
}

export default Register
